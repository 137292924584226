@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&family=Raleway:wght@300;400;500;600;700;800&display=swap");

/* Custom Styles */

.navitem {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent),
        linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in-out;
}

.navitem:hover {
    background-size: 100% 2px;
    background-position: 0 100%;
}
